





























import Lesson from "@/interfaces/Lesson";
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
  watchEffect,
} from "@vue/composition-api";
import Moment from "@/services/moment";
import { userModule } from "@/store/modules/user";

export default defineComponent({
  name: "LessonCardCustomer",
  props: {
    lesson: {
      type: Object as PropType<Lesson>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const vm = reactive({
      isSeller: userModule.state.user.seller,
    });

    const name = computed(() => {
      return `${props.lesson.seller?.profile?.firstName} ${props.lesson.seller?.profile?.lastName}`;
    });

    const readableDate = computed(() => {
      return Moment.unix(props.lesson.startTime).format("dddd Do MMMM");
    });

    const readableTime = computed(() => {
      return `${Moment.unix(props.lesson.startTime).format("HH:mm")} - ${Moment.unix(props.lesson.endTime).format("HH:mm")}`;
    });

    const paid = computed(() => {
      return (
        props.lesson.subtracted ||
        (props.lesson.paymentID && props.lesson.paymentID !== "N/A")
      );
    });

    return {
      _value,
      name,
      paid,
      readableDate,
      readableTime,
      vm,
    };
  },
});
