



























import { userModule } from "@/store/modules/user";
import useAsyncData from "@/hooks/useAsyncData";

import {
  defineComponent,
  onMounted,
  provide,
  reactive,
} from "@vue/composition-api";
import { getLessons } from "@/api/lessonService";
import LessonCalendar from "@/components/lessons/LessonCalendar.vue";
import { getActiveProjects } from "@/api/projectService";
import { ActiveProject } from "@/interfaces/Project";
import Lesson from "@/interfaces/Lesson";
import LessonCardCustomer from "@/components/lessons/LessonCardCustomer.vue";

export default defineComponent({
  name: "LessonsCustomer",
  components: { LessonCalendar, LessonCardCustomer },
  setup() {
    const vm = reactive({
      comingOrPast: "coming",
      lesson: {
        ID: ""
      } as Lesson,
      projects: [] as ActiveProject[],
      showLessonCard: false,
    });

    const { isLoading, data: lessons } = useAsyncData(() =>
      getLessons("customer", userModule.state.userId)
    );

    onMounted(async() => {
      vm.projects = await getActiveProjects("customer", userModule.state.userId);
    });

    const showLessonCard = (lesson: Lesson): void => {
      vm.lesson = lesson;
      vm.showLessonCard = true;
    };

    provide("showLessonCard", showLessonCard);

    return {
      isLoading,
      lessons,
      showLessonCard,
      vm,
    };
  },
});
